@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Ensure consistent height for input and select elements */
.input-bg-light input,
.input-bg-light select {
  color: #4b5563; /* Dark gray text color for light mode input/select */
  height: 40px; /* Set consistent height */
}

/* Light body text color in dark mode */
.dark-body-text {
  color: #8ab7fc;
}

/* Light input/select text color in dark mode */
.dark-body-text input,
.dark-body-text select {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  color: #d1d5db; /* Light gray text color for dark mode input/select */
}
.blur-effect {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
}
